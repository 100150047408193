
var graypes_SERVER_IP = '';
var graypes_auiApp_URL = '';

$.getJSON('/js/graypes_configuration.json',function(data){ 
    graypes_SERVER_IP = data.graypes_SERVER_IP;
    graypes_auiApp_URL = data.graypes_auiApp_URL;
});

// Production on Digital-Ocean
// var graypes_SERVER_IP = 'http://159.65.18.208';
// var graypes_auiApp_URL = "http://159.65.18.208:4200";

// Local on Mac OS X localhost

var authService = {
    login: function login(email, password, done, error) {
        $.ajax({
            type: "POST",
            url: graypes_SERVER_IP + '/gateway-api/api/v1/auth/login',
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify({ email: email, password: password }),
            dataType:'text',
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            complete: function (data) {
                if (data.status !== 200) {
                    error();
                    return;
                }

                done(data.responseText);
            }
        });
    },
    getUser: function getUser(id, done) {
        $.ajax({
            type: "GET",
            url: graypes_SERVER_IP + '/gateway-api/api/v1/users/' + id,
            contentType: 'application/json; charset=utf-8',
            xhrFields: {
                withCredentials: true
            },
            success: function (userJson) {
                var user = {
                    active: userJson.active, company: userJson.company, email: userJson.email,
                    id: userJson.id, name: userJson.name, userCategory: userJson.userCategory, userInterestedIn: userJson.userCategory,
                    userRegion: userJson.userRegion, userTechArea: userJson.userTechArea, userType: userJson.userType, dateCreated: userJson.dateCreated, userDescription: userJson.userDescription
                };

                done(user);
            }
        });
    }
}

$(document).ready(function () {
    $('.sign-in-form').submit(function (evt) {
        $('.invalid-credentials-alert').hide();
        evt.preventDefault();

        var email = $('#email').val();
        var password = $('#password').val();

        authService.login(email, password,
            function (id) {
                authService.getUser(id, function (user) {
                    localStorage.setItem('userID', id);
                    window.location = graypes_auiApp_URL + '/loginToken?token=' + id;
                });
            }, function () {
                $('.invalid-credentials-alert').show();
            });
    });
});